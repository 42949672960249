var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        "max-width": "515"
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h4 px-0"
                        },
                        [_vm._v(" " + _vm._s(_vm.title) + " ")]
                      ),
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "pl-0"
                        },
                        [_vm._v(" " + _vm._s(_vm.description) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("thg-rating-form", {
            attrs: {
              ratingKey: "default"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }